<template>
  <div class="content-wrap class-content-wrap">
    <bread-menu title="课程管理">
      <div class="nav-title">
        <router-link to="" tag="span" class="active">{{menuTitle}}</router-link>
      </div>
    </bread-menu>
    <router-view></router-view>
  </div>
</template>
<script>
import breadMenu from "@/components/breadMenu.vue";
export default {
    name:'classAdmin',
    components:{
        breadMenu
    },
    data(){
      return{
      name:'',
      menuTitle:'课程列表'
      }
    },
    watch:{
     $route: {
     handler:  function (val, oldVal){
       this.changeTitle()
     },
     // 深度观察监听
     deep:  true
   }
    },
    methods:{
      changeTitle(){
        this.name=this.$route.name;
        if(this.name=='classList'){
        this.menuTitle='课时列表'
      }else{
         this.menuTitle='课程列表'
      }
      }
    },
    created(){
      this.changeTitle();
    }
}
</script>
<style scoped>

</style>